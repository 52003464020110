'use strict';

// Directive for Terminals
app.directive('terminalPartnersTab', function() {
    return {
        restrict: 'E',
        templateUrl: 'app/terminals/partners/list.html',
        controller: 'TerminalPartnersController',
        link: function(scope, element, attrs) {
            scope.$on('tabSelected', function(event, tab) {
                if (tab === 'terminals') {
                    scope.getTerminalPartners()
                }
            });
        }
    };
});

// Directive for Tablets
app.directive('tabletPartnersTab', function() {
    return {
        restrict: 'E',
        templateUrl: 'app/tablets/partners/list.html',
        controller: 'TabletPartnersController',
        link: function(scope, element, attrs) {
            scope.$on('tabSelected', function(event, tab) {
                if (tab === 'tablets') {
                    scope.getTabletPartners()
                }
            });
        }
    };
});

// Directive for WebApps
app.directive('webappPartnersTab', function() {
    return {
        restrict: 'E',
        templateUrl: 'app/webapps/partners/list.html',
        controller: 'WebAppPartnersController',
        link: function(scope, element, attrs) {
            scope.$on('tabSelected', function(event, tab) {
                if (tab === 'webapps') {
                    scope.getWebAppPartners()
                }
            });
        }
    };
});

// Main controller to handle tab selection
app.controller('SupportPartnersController', function($scope, $timeout, Auth) {
    $scope.selectedTab = 0;
    $scope.setPermessions = (user=null)=>{
        $scope.hasWebAppsPermission = Auth.hasPermissions('WEBAPPS:GET', user);
        $scope.hasTerminalsPermission = Auth.hasPermissions('TERMINALS:GET', user);
        $scope.hasTabletsPermission = Auth.hasPermissions('TABLETS:GET', user);
    }
    $scope.tabChanged = function(tabIndex) {
        var tabNames = ['terminals', 'tablets', 'webapps'];
        var selectedTab = tabNames[tabIndex];
        $scope.$broadcast('tabSelected', selectedTab);
    };
    $timeout(function() {
        $scope.tabChanged($scope.selectedTab);
    },100);
    $scope.setPermessions()
});
